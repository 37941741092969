<!--suppress JSUnusedLocalSymbols -->
<template>
  <div v-loading="isLoading">
    <b-card>
      <b-row>
        <b-col lg="6">
          <h3 class="mb-1">{{ $t(getTypeForDisplay()) }} {{ invoice.invoiceNumber }}</h3>
          <p class="invoice-row"><strong>Customer Reference Number:</strong> {{ invoice.customerReferenceNumber }} <i class="el-icon-edit" v-if="isInvoiceEditable()" @click="editSingleField('customerReferenceNumber', 'Customer Reference Number')"></i></p>
          <p class="invoice-row"><strong>{{ invoice.udf1Name ? invoice.udf1Name : 'UDF' }} <i class="el-icon-edit" v-if="isInvoiceEditable()" @click="editSingleField('udf1Name', 'UDF Name')"></i>:</strong> {{ invoice.udf1 }} <i class="el-icon-edit" v-if="isInvoiceEditable()" @click="editSingleField('udf1', 'UDF')"></i></p>
          <p class="invoice-row"><strong>Remark:</strong> {{ invoice.remark }}<i class="el-icon-edit" v-if="isInvoiceEditable()" @click="editSingleField('remark', 'Remark')"></i></p>

          <p class="invoice-row"><strong>Account Code:</strong> {{ invoice.accountCode }}</p>
          <p class="invoice-row"><strong>Currency Profile:</strong> {{ currencyProfile.id ? currencyProfile.name : invoice.currencyProfileId }}</p>
          <p class="invoice-row"><strong>Currency Profile Rate:</strong> <span v-if="!invoice.currencyProfileBillingPeriod">Latest Effective Rate</span><span v-else>Specific Billing Period: {{ invoice.currencyProfileBillingPeriod }}</span> <i class="el-icon-edit" v-if="isInvoiceEditable()" @click="editProfileRate()"></i></p>
          <p class="invoice-row"><strong>Total Due:</strong> {{ invoice.totalAmount }} {{ invoice.totalAmountCurrency }}</p>
        </b-col>
        <b-col lg="6">
          <el-button type="primary" class="mr-1 float-right" v-if="action === 'view' && getErpCanWriteGlobal()" @click="goEdit()">Edit</el-button>
          <el-button type="primary" class="mr-1 float-right" v-if="action === 'edit'" @click="goView()">View Only</el-button>
          <el-button type="warning" class="mr-1 float-right" v-if="invoice.accruals && isAccrualEnabled()" @click="showAccruals()">Accruals ({{ invoice.accruals.length }})</el-button>
          <el-button type="info" class="mr-1 float-right" v-if="isInvoiceEditable()" @click="isShowEditFootnote = true">Edit Footnote</el-button>
          <h3 class="mb-1">&nbsp;</h3>
          <p class="invoice-row"><strong>{{ getTypeForDisplay() }} Status:</strong> <InvoiceStatusTag :item="invoice"/></p>
          <p class="invoice-row"><strong>Billing Period:</strong> {{ invoice.billingPeriodStart }} - {{ invoice.billingPeriodEnd }} ({{ invoice.billingPeriod }} <i class="el-icon-edit" v-if="isInvoiceEditable()" @click="editSingleField('billingPeriod', 'Billing Period (Sun)')"></i>)</p>
          <p class="invoice-row"><strong>{{ getTypeForDisplay() }} Time:</strong> {{ formatWithCurrentTimezone(invoice.invoiceTime) }}</p>
          <p class="invoice-row"><strong>Confirm Time:</strong> {{ formatWithCurrentTimezone(invoice.confirmTime) }}</p>
          <p class="invoice-row"><strong>F&amp;A Confirm Time:</strong> {{ formatWithCurrentTimezone(invoice.confirmFaTime) }}</p>
          <p class="invoice-row"><strong>Sun Close Time:</strong> {{ formatWithCurrentTimezone(invoice.sunCloseTime) }}</p>
          <p class="invoice-row"><strong>Sent Time:</strong> {{ formatWithCurrentTimezone(invoice.sentTime) }}</p>
          <p class="invoice-row" v-if="invoice.type !== 'CREDIT_NOTE'"><strong>Due Date:</strong> {{ formatWithCurrentTimezone(invoice.dueTime) }}</p>
          <p class="invoice-row"><strong>Payment Terms:</strong> {{ invoice.paymentTermDays }} Days</p>
          <p class="invoice-row" v-if="isAccrualEnabled()"><strong>Accrual Billing Period:</strong> {{ invoice.accrualBillingPeriod }}</p>
          <p class="invoice-row" v-if="isAccrualEnabled()"><strong>Accrual Amount Period:</strong> {{ invoice.accrualTotalAmount }} {{ invoice.accrualTotalAmountCurrency }}</p>

        </b-col>
      </b-row>

      <hr/>

      <b-row>
        <b-col lg="6">
          <h3>{{ invoice.type === 'CREDIT_NOTE' ? $t('To') : $t('Invoice To') }}</h3>
          <p class="invoice-row">{{ organization.companyName }}</p>
          <p class="invoice-row">{{ organization.companyAddress }}</p>
          <div v-for="companyContact in organization.companyContacts" class="mt-2">
            <p class="invoice-row">{{ companyContact.name }}</p>
            <p class="invoice-row">{{ companyContact.phone }}</p>
            <p class="invoice-row">{{ companyContact.email }}</p>
          </div>
        </b-col>
        <b-col lg="6">
          <h3>{{ $t('Bill To') }}</h3>
          <div v-for="faContact in organization.faContacts" class="mt-2">
            <p class="invoice-row">{{ faContact.name }}</p>
            <p class="invoice-row">{{ faContact.phone }}</p>
            <p class="invoice-row">{{ faContact.email }}</p>
          </div>
          <p class="invoice-row">&nbsp;</p>
        </b-col>
      </b-row>
    </b-card>

    <hr/>

    <h5>Charges</h5>

    <div class="mb-1" v-if="invoice.type !== 'CREDIT_NOTE'">
      <span class="mr-1 text-center" :style="getInvoiceIconStyle('CBO', invoice)">CBO ⬤</span>
      <span class="mr-1 text-center" :style="getInvoiceIconStyle('Fulfillment', invoice)">Fulfillment ⬤</span>
      <span class="mr-1 text-center" :style="getInvoiceIconStyle('Others', invoice)">Other ⬤</span>
    </div>

    <el-table :data="invoice.items" :row-class-name="tableRowClassName">
      <el-table-column prop="chargeCode" label="Code" width="100">
        <template v-slot="scope">
          <el-tooltip class="item" effect="dark" content="Charge Code not included in any Accruals" placement="top-start">
            <i class="el-icon-warning warning" v-if="!isChargeCodeValid(scope.row.warehouseCode, invoice)"></i>
          </el-tooltip>
          {{ scope.row.chargeCode }}
        </template>
      </el-table-column>
      <el-table-column prop="warehouseCode" label="W/H Code" width="100"/>
      <el-table-column prop="originalAmount" label="Unit Price" width="150">
        <template v-slot="scope">{{ scope.row.unitPrice }} {{ scope.row.originalCurrency }}</template>
      </el-table-column>
      <el-table-column prop="qty" label="Qty" width="60"/>
      <el-table-column prop="originalAmount" label="Original Amount" width="180">
        <template v-slot="scope">{{ scope.row.originalAmount }} {{ scope.row.originalCurrency }}</template>
      </el-table-column>
      <el-table-column prop="amount" label="Converted Amount" width="180">
        <template v-slot="scope">
          <el-tooltip class="item" effect="dark" :content="scope.row.originalCurrency === scope.row.currency ? '-' : scope.row.originalCurrency + '/' + scope.row.currency + ': ' + scope.row.exchangeRate" placement="top">
            <span>{{ scope.row.amount }} {{ scope.row.currency }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="Accrual Amount" width="180">
        <template v-slot="scope">
          <el-tooltip v-if="scope.row.accrualAmount !== null" class="item" effect="dark" :content="scope.row.currency === scope.row.accrualAmountCurrency ? '-' : scope.row.currency + '/' + scope.row.accrualAmountCurrency + ': ' +scope.row.accrualExchangeRate" placement="top">
            <span>{{ scope.row.accrualAmount }} {{ scope.row.accrualAmountCurrency }}</span>
          </el-tooltip>
          <div v-else>Unconfirmed</div>

          <div>
            <div v-for="accrual in invoice.accruals" v-if="accrual.warehouseCode === scope.row.warehouseCode" class="text-muted">
              <span class="el-icon-price-tag orange"></span>Inv: {{ accrual.accrualNumber }}
            </div>
            <div v-if="scope.row.accrualId !== null" class="text-muted">
              <span class="el-icon-price-tag orange"></span>Item: {{ scope.row.accrual.accrualNumber }}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="billingPeriod" label="Billing Period" width="120"/>
      <el-table-column prop="description" label="Description"/>
      <el-table-column prop="remark" label="Remark"/>
      <el-table-column prop="actions" label="Actions" width="300" v-if="isInvoiceEditable()">
        <template v-slot:header="scope">
          <el-button type="success" size="small" class="float-right" @click="showAddItem();" v-if="isInvoiceEditable()">
            <i class="el-icon-plus"></i>
            Add Item
          </el-button>
        </template>

        <template v-slot="scope">
          <el-button type="danger" size="small" class="float-right" @click="showDeleteItem(scope.row)" v-if="isInvoiceEditable()">
            <i class="el-icon-delete"></i>
            Delete Item
          </el-button>

          <el-button type="primary" size="small" class="float-right mr-1" @click="showEditItem(scope.row)" v-if="isInvoiceEditable()">
            <i class="el-icon-edit"></i>
            Edit Item
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <hr/>

    <h5>
      Attachments
      <el-button type="success" size="small" class="float-right"  @click="addDocument()" v-if="isInvoiceEditable()">
        <i class="el-icon-plus"></i>
        Add Attachment
      </el-button>
      <span class="clearfix"></span>
    </h5>

    <b-row lg="12" v-for="(document, k) in documents" :key="k">
      <b-col lg="3">
        <b-form-group :label="$t('Document Type')">
          <template v-slot:label="scope">
            <span class="required" v-if="document.isRequired">*</span>
            <strong>{{ $t('Document Type') }}</strong>
          </template>
          <el-input size="small" v-model="document.type" :readonly="document.isRequired" @change="updateDocument(document)"/>
        </b-form-group>
      </b-col>
      <b-col lg="9" class="text-right">
        <b-form-group>
          <el-button class="mt-1" size="small" v-if="document.clientFilename" @click="openDocument(document, k)">{{ document.clientFilename ? document.clientFilename : 'No File' }}</el-button>
          <el-button class="mt-1" size="small" v-if="isInvoiceEditable() && !document.clientFilename" @click="uploadDocument(document, k)">{{ document.clientFilename ? document.clientFilename : 'Select File' }}</el-button>
          <el-button class="mt-1" size="small" v-if="isInvoiceEditable() && document.clientFilename" @click="removeDocument(document, k)" type="danger"><i class="el-icon-delete"></i> Remove</el-button>
        </b-form-group>
      </b-col>
    </b-row>

    <hr/>

    <div class="actions mt-1 mb-1">
      <el-button type="info" size="small" class="float-right ml-1" @click.stop="$refs.invoiceEmailView.emailInvoice(invoice)" v-if="action === 'edit' && (invoice.status === 'CONFIRMED' || invoice.status === 'SENT')">
        <i class="el-icon-document"></i>
        Email
      </el-button>

      <el-button type="info" size="small" class="float-right ml-1" @click.stop="$refs.invoiceCopyView.copyInvoice(invoice, organization)" v-if="action === 'edit'">
        <i class="el-icon-copy-document"></i>
        Copy
      </el-button>

      <el-button type="success" size="small" class="float-right ml-1" @click.stop="$refs.invoiceConfirmView.confirmInvoice(invoice)" v-if="isInvoiceEditable()">
        <i class="el-icon-check"></i>
        Confirm
      </el-button>

      <el-button type="warning" size="small" class="float-right ml-1" @click.stop="$refs.invoiceReviseView.reviseInvoice(invoice)" v-if="action === 'edit' && invoice.status === 'CONFIRMED'">
        <i class="el-icon-back"></i>
        Revise
      </el-button>

      <el-button type="success" size="small" class="float-right ml-1" @click.stop="$refs.invoiceConfirmFaView.confirmFaInvoice(invoice)" v-if="action === 'edit' && invoice.status === 'CONFIRMED'">
        <i class="el-icon-check"></i>
        F&amp;A Confirm
      </el-button>

      <el-button type="success" size="small" class="float-right ml-1" @click.stop="$refs.invoiceSunCloseView.closeSunInvoice(invoice)" v-if="action === 'edit' && invoice.status === 'FA_CONFIRMED' && invoice.sunCloseTime === null">
        <i class="el-icon-check"></i>
        Sun Close
      </el-button>

      <el-button type="primary" size="small" class="float-right ml-1" @click.stop="downloadInvoice(invoice)">
        <i class="el-icon-paperclip"></i>
        Download PDF
      </el-button>

      <div class="clearfix"></div>
    </div>

    <el-dialog
      class="compact"
      :title="$t(showAction(popupAction)) + ' - ' + invoice.invoiceNumber"
      :visible.sync="isShowEditItem"
      width="50%"
    >
      <el-form :model="newItem" ref="mainForm" v-loading="isLoading">
        <b-row lg="12">
          <b-col lg="4">
            <el-form-item :label="$t('Charge Code')" prop="chargeCode">
              <el-select size="small" filterable v-model="newItem.chargeCode" class="w-100" @change="onChargeCodeChange()">
                <el-option v-for="item in chargeCodes" :key="item.code" :label="item.value" :value="item.code" v-if="item.status === 'ACTIVE'">
                  {{ item.category }} &gt; {{ item.level1 }} &gt; {{ item.level2 }} &gt; {{ item.code }}
                </el-option>
              </el-select>
            </el-form-item>
          </b-col>

          <b-col lg="4">
            <el-form-item :label="$t('Warehouse Code')" prop="warehouseCode">
              <el-select filterable size="small" v-model="newItem.warehouseCode" class="w-100">
                <el-option v-for="item in warehouseCodes" :key="item" :label="item" :value="item">
                  {{ item }}
                </el-option>
              </el-select>
            </el-form-item>
          </b-col>

          <b-col lg="4">
            <el-form-item :label="$t('Billing Period')" prop="chargeCode">
              <el-input size="small" type="text" v-model="newItem.billingPeriod"/>
            </el-form-item>
          </b-col>
        </b-row>

        <b-row lg="12">
          <b-col lg="2">
            <el-form-item :label="$t('Unit Price')" prop="unitPrice">
              <el-input size="small" type="number" step="0.01" v-model="newItem.unitPrice" class="disableable" @change.native="updateItemTotal(newItem)"/>
            </el-form-item>
          </b-col>

          <b-col lg="2">
            <el-form-item :label="$t('Item Currency')" prop="amount">
              <el-select filterable size="small" v-model="newItem.originalCurrency" @change="updateItemTotal(newItem)">
                <el-option v-for="item in currencies" :key="item" :label="item" :value="item">
                  {{ item }}
                </el-option>
              </el-select>
            </el-form-item>
          </b-col>

          <b-col lg="1">
            <el-form-item :label="''" prop="times">
              <div style="font-size: 48px; text-align: center; position: relative; top: 42px">&times;</div>
            </el-form-item>
          </b-col>

          <b-col lg="2">
            <el-form-item :label="$t('Qty')" prop="qty">
              <el-input size="small" type="number" step="0.01" v-model="newItem.qty" class="disableable" @change.native="updateItemTotal(newItem)"/>
            </el-form-item>
          </b-col>

          <b-col lg="1">
            <el-form-item :label="''" prop="equals">
              <div style="font-size: 48px; text-align: center; position: relative; top: 42px">=</div>
            </el-form-item>
          </b-col>

          <b-col lg="2">
            <el-form-item :label="$t('Total ' +  ' ' + newItem.originalCurrency)" prop="total">
              <el-input size="small" v-model="newItem.originalAmount" class="disableable" readonly="readonly"/>
            </el-form-item>
          </b-col>

          <b-col lg="2">
            <el-form-item :label="$t('Total ' +  ' ' + newItem.currency)" prop="total">
              <el-input size="small" v-model="newItem.amount" class="disableable" readonly="readonly"/>
            </el-form-item>
          </b-col>
        </b-row>

        <b-row lg="12">
          <b-col lg="6">
            <el-form-item :label="$t('Description')" prop="description">
              <el-input size="small" v-model="newItem.description" maxlength="30"/>
            </el-form-item>
          </b-col>
          <b-col lg="6">
            <el-form-item :label="$t('Remarks')" prop="total">
              <el-input size="small" v-model="newItem.remark"/>
            </el-form-item>
          </b-col>
        </b-row>

        <b-row lg="12">
          <b-col lg="6">
            <el-form-item :label="$t('Item Level Accrual')" prop="accrualId" v-if="invoice.accruals.length === 0">
              <el-select v-model="newItem.accrualId" value-key="id" clearable>
                <el-option v-for="item in companyAccruals" :key="item.id" :label="item.accrualNumber" :value="item.id" v-if="item.deleteTime === null" :disabled="(item.chargeCodeEntity && item.chargeCodeEntity.type === 'AP') || item.confirmTime !== null">
                  {{ item.accrualNumber }} / Type: {{ item.chargeCodeEntity && item.chargeCodeEntity.type ? item.chargeCodeEntity.type : 'Unspecified' }} / Charge Code: {{ item.chargeCode }} / Warehouse Code: {{ item.warehouseCode }}
                </el-option>
              </el-select>
            </el-form-item>

            <div v-else>
              Either Invoice Level or Item Level Accrual can be used.
            </div>
          </b-col>
        </b-row>

      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button  type="primary" @click="saveItem()" :disabled="isLoading || !isItemAddable">Save</el-button>
      </span>
    </el-dialog>

    <el-dialog
      class="compact"
      title="Edit Footnote"
      :visible.sync="isShowEditFootnote"
      width="50%"
    >
      <h5>{{ $t('Conversion Rates') }}</h5>
      <el-input type="textarea" v-model="invoice.exchangeRates" :autosize="{ minRows: 2, maxRows: 10}" />
      <hr/>

      <h5>{{ $t('Footnote 1') }}</h5>
      <el-input type="textarea" v-model="invoice.footnote1" :autosize="{ minRows: 2, maxRows: 10}" />
      <hr/>

      <h5>{{ $t('Footnote 2') }}</h5>
      <el-input type="textarea" v-model="invoice.footnote2" :autosize="{ minRows: 2, maxRows: 10}" />
      <hr/>

      <h5>{{ $t('Footnote 3') }}</h5>
      <el-input type="textarea" v-model="invoice.footnote3" :autosize="{ minRows: 2, maxRows: 10}" />
      <hr/>

      <el-button type="success" class="float-right" @click="saveFootnote()">Save Footnote</el-button>
      <div class="clearfix"></div>
    </el-dialog>

    <el-dialog
      class="compact"
      title="Accruals"
      :visible.sync="isShowAccruals"
      width="80%"
    >
      <b-row lg="12">
          <table class="table mb-2">
            <thead>
              <tr>
                <th>Accrual Number</th>
                <th>Charge Code</th>
                <th>Category</th>
                <th>Level 1</th>
                <th>Level 2</th>
                <th>Warehouse Code</th>
                <th>Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="accrual in invoice.accruals">
                <td>{{ accrual.accrualNumber }}</td>
                <td>{{ accrual.chargeCode }}</td>
                <td>{{ accrual.chargeCodeCategory }}</td>
                <td>{{ accrual.chargeCodeLevel1 }}</td>
                <td>{{ accrual.chargeCodeLevel2 }}</td>
                <td>{{ accrual.warehouseCode }}</td>
                <td>{{ accrual.totalAmount }} {{ accrual.currency }}</td>
                <td>
                  <el-link @click="viewAccrual(accrual)"><i class="el-icon-view"></i> View/Edit</el-link>
                  <el-link @click="removeAccrual(accrual)" class="ml-1" v-if="isInvoiceEditable()"><i class="el-icon-delete"></i> Remove</el-link>
                </td>
              </tr>
            </tbody>
          </table>

          <div v-if="isInvoiceEditable()">
            <hr/>

            <div v-if="invoice.accruals.length === 0">
              <div v-if="invoice.items.map(item => item.accrualId).filter(i => i).length > 0">
                Either Invoice Level or Item Level Accrual can be used.
              </div>
              <div v-else>
                <el-select v-model="newAccrual" value-key="id">
                  <el-option v-for="item in companyAccruals" :key="item.id" :label="item.accrualNumber" :value="item" v-if="item.deleteTime === null" :disabled="(item.chargeCodeEntity && item.chargeCodeEntity.type === 'AP') || item.confirmTime !== null">
                    {{ item.accrualNumber }} / Type: {{ item.chargeCodeEntity && item.chargeCodeEntity.type ? item.chargeCodeEntity.type : 'Unspecified' }} / Charge Code: {{ item.chargeCode }} / Warehouse Code: {{ item.warehouseCode }}
                  </el-option>
                </el-select>

                <el-button type="primary" class="ml-1" @click="addNewAccrualSubmit()">Link Invoice Level Accrual</el-button>
              </div>
            </div>
            <div v-else>
              Only one Invoice Level Accrual is allowed.
            </div>
          </div>
      </b-row>
    </el-dialog>

    <el-dialog
      title="Select Profile Rate"
      :visible.sync="isShowEditProfileRate"
      width="30%"
    >
      <el-radio v-model="profileRate.currencyProfileRateMode" label="latest">Latest Effective Rate</el-radio>
      <el-radio v-model="profileRate.currencyProfileRateMode" label="billingPeriod">Specific Billing Period</el-radio>

      <div class="my-1" v-if="profileRate.currencyProfileRateMode === 'billingPeriod'">
        <el-select v-model="profileRate.currencyProfileBillingPeriod">
          <el-option v-for="item in currencyProfileBillingPeriods" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </div>

      <hr/>

      <el-button type="success" class="float-right" @click="saveProfileRate()">Save Profile Rate</el-button>
      <div class="clearfix"></div>
    </el-dialog>

    <UploadModal ref="uploadView"></UploadModal>

    <InvoiceCopyModal ref="invoiceCopyView"></InvoiceCopyModal>
    <InvoiceEmailModal ref="invoiceEmailView"></InvoiceEmailModal>
    <InvoiceReviseModal ref="invoiceReviseView"></InvoiceReviseModal>
    <InvoiceConfirmModal ref="invoiceConfirmView"></InvoiceConfirmModal>
    <InvoiceConfirmFaModal ref="invoiceConfirmFaView"></InvoiceConfirmFaModal>
    <InvoiceSunCloseModal ref="invoiceSunCloseView"></InvoiceSunCloseModal>
  </div>
</template>

<script>
import { getErpCanWriteGlobal, getErpProfile } from "@/utils/account-localstorage";
import { BButton, BModal } from "bootstrap-vue";
import apis from "@/configs/apis";
import { getInvoiceIconStyle } from "@/views/erp/utils/invoice";
import UploadModal from "@/views/erp/components/UploadModal.vue";
import { EventBus } from "@/views/erp/utils/event-bus";
import InvoiceStatusTag from "@/views/erp/components/InvoiceStatusTag.vue";
import _ from "lodash";
import { formatWithCurrentTimezone } from "./utils/time";
import { isAccrualEnabled, MAX_PAGE_SIZE } from "@/views/erp/utils/system";
import File from "@/views/erp/mixins/File";
import Prompt from "@/views/erp/mixins/Prompt";
import axios from "axios";
import Invoice from "@/views/erp/mixins/Invoice";
import InvoiceConfirmModal from "@/views/erp/components/InvoiceConfirmModal.vue";
import InvoiceConfirmFaModal from "@/views/erp/components/InvoiceConfirmFaModal.vue";
import InvoiceReviseModal from "@/views/erp/components/InvoiceReviseModal.vue";
import InvoiceEmailModal from "@/views/erp/components/InvoiceEmailModal.vue";
import InvoiceCopyModal from "@/views/erp/components/InvoiceCopyModal.vue";
import InvoiceSunCloseModal from "@/views/erp/components/InvoiceSunCloseModal.vue";
import { showAction } from "@/views/erp/utils/string";

export default {
  mixins: [File, Prompt, Invoice],
  components: {
    InvoiceCopyModal,
    InvoiceEmailModal,
    InvoiceReviseModal,
    InvoiceConfirmModal,
    InvoiceConfirmFaModal,
    InvoiceSunCloseModal,
    InvoiceStatusTag,
    UploadModal,
    BButton,
    BModal,
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },

      id: null,
      invoice: {
        accruals: []
      },
      currencyProfile: {},
      organization: {},
      documents: [],
      chargeCodes: [],
      warehouseCodes: [],
      currencies: [],
      currencyProfileBillingPeriods: [],
      chargeCodeWhitelist: [],
      popupAction: null,
      newItem: {
        chargeCode: null,
        qty: 1,
        currency: null,
        originalAmount: 0,
        billingPeriod: "",
        warehouseCode: "",
        description: "",
        remark: ""
      },
      profileRate: {
        currencyProfileRateMode: 'latest',
        currencyProfileBillingPeriod: null,
      },

      newAccrual: null,
      companyAccruals: [],
      isItemAddable: true,
      isShowEditItem: false,
      isShowEditFootnote: false,
      isShowEditProfileRate: false,
      isShowAccruals: false,
      isShowConfirmAccruals: false,
      isLoading: false,
    };
  },
  props: {
    action: String,
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.initialize();
  },
  watch: {
    '$route'() {
      console.log('Route changed, reloading...');
      this.initialize();
    }
  },
  methods: {
    showAction,
    isAccrualEnabled,
    formatWithCurrentTimezone,
    getErpCanWriteGlobal,
    getInvoiceIconStyle,
    getErpProfile,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    initialize() {
      this.id = this.$route.params.id;
      this.company_id = this.$route.params.company_id;

      if (this.action === 'edit' && !getErpCanWriteGlobal()) {
        this.goView();
      }

      this.loadChargeCodes();
      this.loadWarehouseCodes();
      this.loadInvoice();

      // EventBus.$on('invoice-created', () => {
      //   this.initialize();
      // });

      EventBus.$on('invoice-updated', () => {
        this.loadInvoice();
      });
    },

    async loadChargeCodes() {
      const response = await axios.get(apis.erpChargeCodes);
      this.chargeCodes = response.data.data.data;
    },

    async loadWarehouseCodes() {
      axios.get(
        apis.erpGetMasterData + "/WAREHOUSE_CODE/LIST",
      ).then(response => {
        this.warehouseCodes = response.data.data.value;
      })
      .catch(e => {
        this.promptError(e);
      })
    },

    showAccruals() {
      this.isShowAccruals = true;
    },

    loadInvoice() {
      this.isLoading = true;
      axios.get(apis.erpInvoices + '/' + this.id, {params: {extendedInfo: true}})
        .then(response => {
          this.invoice = response.data.data;

          this.loadOrganization();
          this.loadDocument();
          this.loadCurrencyProfile();
          this.loadCurrencyProfileItems();
        })
        .catch(e => {
          this.promptError(e);
        })
        .finally(() => {
          this.isLoading = false;
        })
    },

    loadCurrencyProfile() {
      axios.get(apis.erpCurrencyProfiles + '/' + this.invoice.currencyProfileId)
        .then(response => {
          this.currencyProfile = response.data.data;
        })
    },

    loadCurrencyProfileItems() {
      axios.get(apis.erpCurrencyProfileItems, {params: {currencyProfileId: this.invoice.currencyProfileId}})
        .then(response => {
          let currencies = [];
          let billingPeriods = [];
          response.data.data.data.forEach(item => {
            currencies.push(item.baseCurrency);
            billingPeriods.push(item.billingPeriod);
          });

          currencies.push(this.organization.paymentCurrency);
          this.currencies = _.uniq(currencies).sort();
          this.currencyProfileBillingPeriods = _.uniq(billingPeriods).sort().reverse();
        })
    },

    showAddItem() {
      this.newItem = {
        originalAmount: 0,
        amount: 0,
        originalCurrency: this.invoice.totalAmountCurrency,
        currency: this.invoice.totalAmountCurrency,
        billingPeriod: this.invoice.billingPeriod,
        description: "",
        remark: ""
      }

      this.popupAction = 'ADD';
      this.isShowEditItem = true;
    },

    showEditItem(item) {
      this.popupAction = 'EDIT';
      this.isShowEditItem = true;
      this.newItem = item;
    },

    showDeleteItem(item) {
      this.$confirm('Confirm delete this item?', 'Delete Invoice Item: ' + item.chargeCode)
        .then(() => {
          return axios.delete(apis.erpInvoices + '/' + this.id + '/item/' + item.id, this.newItem);
        })
        .then(response => {
          this.promptInfo('Invoice Item Deleted');
          this.loadInvoice();
        })
        .catch(e => {
        this.promptError(e);
      })
    },

    updateItemTotal(item) {
      if (isNaN(this.newItem.qty) || isNaN(this.newItem.unitPrice)) return;

      this.isLoading = true;

      this.newItem.unitPrice = parseFloat(this.newItem.unitPrice).toFixed(2);
      this.newItem.qty = parseFloat(this.newItem.qty).toFixed(2);

      this.newItem.originalAmount = (this.newItem.unitPrice * this.newItem.qty).toFixed(2);

      axios.post(apis.erpInvoices + '/' + this.id + '/conversion-rate', { originalAmount: this.newItem.unitPrice * this.newItem.qty, originalCurrency: this.newItem.originalCurrency })
        .then(result => {
          this.newItem.amount = result.data.data.quoteAmount.toFixed(2);
          this.isItemAddable = true;
        })
        .catch((e) => {
          this.isItemAddable = false;
          this.promptError(e);
        })
        .finally(() => {
          this.isLoading = false;
          this.$forceUpdate();
        })
    },

    editSingleField(fieldKey, fieldName) {
      this.$prompt('Please enter ' + fieldName, '', {
        inputValue: this.invoice[fieldKey],
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel'
      }).then(({ value }) => {
        let data = {};
        data[fieldKey] = value;

        axios.patch(apis.erpInvoices + '/' + this.id, data)
          .then(response => {
            this.promptInfo(fieldName + ' Updated');
            this.loadInvoice();
          })
          .catch(e => {
            this.promptError(e);
          });
      }).catch(() => {
        //
      });
    },

    editProfileRate() {
      this.isShowEditProfileRate = true;

      if (this.invoice.currencyProfileBillingPeriod) {
        this.profileRate.currencyProfileRateMode = 'billingPeriod';
        this.profileRate.currencyProfileBillingPeriod = this.invoice.currencyProfileBillingPeriod;
      } else {
        if (this.currencyProfileBillingPeriods.indexOf(this.invoice.billingPeriod) >= 0) {
          this.profileRate.currencyProfileBillingPeriod = this.invoice.billingPeriod;
        }
      }
    },

    saveProfileRate() {
      axios.patch(apis.erpInvoices + '/' + this.id, this.profileRate)
        .then(response => {
          this.promptInfo('Invoice Profile Rate Updated');
          this.loadInvoice();
          this.isShowEditProfileRate = false;
        })
        .catch(e => {
          this.promptError(e);
        });
    },

    saveFootnote() {
      axios.patch(apis.erpInvoices + '/' + this.id, {
        exchangeRates: this.invoice.exchangeRates,
        footnote1: this.invoice.footnote1,
        footnote2: this.invoice.footnote2,
        footnote3: this.invoice.footnote3,
      })
        .then(response => {
          this.promptInfo('Invoice Footnote Updated');
          this.loadInvoice();
          this.isShowEditFootnote = false;
        })
        .catch(e => {
          this.promptError(e);
        });
    },

    saveItem() {
      if (this.popupAction === 'ADD') {
        axios.post(apis.erpInvoices + '/' + this.id + '/item', this.newItem)
          .then(response => {
            this.promptInfo('Invoice Item Added');
            this.isShowEditItem = false;
            this.loadInvoice();
          })
          .catch(e => {
            this.promptError(e);
          });
      } else if (this.popupAction === 'EDIT') {
        axios.put(apis.erpInvoices + '/' + this.id + '/item/' + this.newItem.id, this.newItem)
          .then(response => {
            this.promptInfo('Invoice Item Updated');
            this.isShowEditItem = false;
            this.loadInvoice();
          })
          .catch(e => {
            this.promptError(e);
          });
      }
    },

    loadOrganization() {
      axios.get(apis.erpOrganizationProfile + '/' + this.invoice.organizationProfileId)
        .then(res => {
          this.organization = res.data.data;

          try {
            // Update breadcrumb
            this.$route.meta.breadcrumb[1].text = this.organization.companyName;
            this.$route.meta.breadcrumb[2].text = this.getTypeForDisplay();
            this.$route.meta.breadcrumb[3].text = this.invoice.invoiceNumber;

            if (this.invoice.type === 'CREDIT_NOTE') {
              this.$route.meta.breadcrumb[2].to = '../..#credit-note'
            }
          } catch (e) {
            //
          }

          this.loadCompanyAccrual();

          EventBus.$emit("route-meta-updated");
        }).catch(e => {
          this.promptError(e);
        })
    },

    loadDocument() {
      axios.get(apis.erpInvoices + '/' + this.invoice.id + '/document')
        .then(res => {
          this.documents = res.data.data.data;
          this.addInvoiceSummaryIfNeeded();
        }).catch(e => {
          this.promptError(e);
        })
    },

    addInvoiceSummaryIfNeeded() {
      if (this.documents.filter(document => { return document.type === 'Invoice Summary'}).length > 0) return;
      if (this.invoice.status !== 'PENDING' || this.action !== 'edit') return;

      this.documents.push({
          type: 'Invoice Summary',
          clientFilename: null,
          serverFilename: null,
          isRequired: true,
        })
    },

    addDocument() {
      this.documents.push({
        type: '',
        clientFilename: null,
        serverFilename: null,
        isRequired: false,
      })
    },

    updateDocument(document) {
      axios.patch(apis.erpInvoices + '/' + this.invoice.id + '/document/' + document.id, document)
        .then(response => {
          this.promptInfo(document.type + ' updated')
        }).catch(e => {
          this.promptError(e);
        })
    },

    uploadDocument(document) {
      this.$refs.uploadView.showUpload('Upload Document', 'INVOICE_SUMMARY', {maxSize: 10485760}, (form) => {
        this.isLoading = true;

        form.type = document.type;
        form.isRequired = document.isRequired;

        axios.post(apis.erpInvoices + '/' + this.invoice.id + '/document', form)
          .then(response => {
            this.$refs.uploadView.hideUpload();

            this.promptInfo('Attachment ' + document.type + ' uploaded')
            this.loadDocument()
          }).catch(e => {
            console.log(e);
            this.promptError(e);
          }).finally(() => {
            this.isLoading = false;
          });
      });
    },

    openDocument(document) {
      this.downloadFile('INVOICE_SUMMARY', document.serverFilename, document.clientFilename);
    },

    removeDocument(document, index) {
      axios.delete(apis.erpInvoices + '/' + this.invoice.id + '/document/' + document.id)
        .then(response => {
          this.loadDocument();
          this.promptInfo('Attachment ' + document.type + ' deleted')
        }).catch(e => {
          this.promptError(e);
        })
    },

    loadCompanyAccrual() {
      return axios.get(apis.erpAccruals, {params: {accountCode: this.organization.accountCode, pageSize: MAX_PAGE_SIZE}})
        .then(response => {
          this.companyAccruals = response.data.data.data;
        })
    },

    addNewAccrualSubmit() {
      axios.post(apis.erpInvoices + '/' + this.invoice.id + '/accruals', {accrualNumber: this.newAccrual.accrualNumber})
        .then((response) => {
          this.loadInvoice();
        }).catch((e) => {
          this.promptError(e)
        });
    },

    goView() {
      this.$router.push({
        name: 'erp-invoice-view',
        params: { id: this.id, company_id: this.company_id }
      })
    },

    goEdit() {
      this.$router.push({
        name: 'erp-invoice-edit',
        params: { id: this.id, company_id: this.company_id }
      })
    },

    viewAccrual(accrual) {
      this.$router.push({
        name: 'erp-company-profile',
        params: { id: this.invoice.organizationProfileId },
        hash: '#accrual-' + accrual.id
      })
    },

    removeAccrual(accrual) {
      this.$confirm('Confirm remove accrual from invoice?', 'Accrual: ' + accrual.accrualNumber)
        .then(() => {
          return axios.delete(apis.erpInvoices + '/' + this.id + '/accruals/' + accrual.id)
        })
        .then(response => {
          this.promptInfo('Accrual removed');
          this.loadInvoice();
        })
        .catch(e => {
          this.promptError(e);
        });
    },

    shouldShowChargeCode(code) {
      if (this.invoice.accruals.length === 0) return true;

      let result = false;

      this.invoice.accruals.forEach(accrual => {
        if (code === accrual.chargeCode) result = true;
      });

      return result;
    },

    shouldShowWarehouseCode(warehouseCode) {
      if (!this.newItem || !this.newItem.chargeCode) return true;
      if (this.invoice.accruals.length === 0) return true;

      let result = false;

      this.invoice.accruals.forEach(accrual => {
        if (this.newItem.chargeCode === accrual.chargeCode && warehouseCode === accrual.warehouseCode) result = true;
      });

      return result;
    },

    onChargeCodeChange(newChargeCode) {
      if (!this.shouldShowWarehouseCode(this.newItem.warehouseCode)) this.newItem.warehouseCode = null;
    },

    getTypeForDisplay() {
      return this.invoice.type === 'CREDIT_NOTE' ? 'Credit Note' : 'Invoice';
    },

    isInvoiceEditable() {
      return this.action === 'edit' && this.invoice.confirmTime === null;
    },

    tableRowClassName({row, rowIndex}) {
      if (!this.isChargeCodeValid(row.warehouseCode, this.invoice)) return 'warning-row';
      return '';
    }
  }
};
</script>

<style scoped>
.invoice-row .el-icon-edit {
  color: orange;
  cursor: pointer;
}

p.invoice-row {
  line-height: 16px;
  margin-bottom: 0.5rem;
}
</style>
